import { Project } from "../../models/project"
import { Menu } from "../../models/menu"
import { Queue, QueueType } from "../../models/queue"
import { SimpleUser, User } from "../../models/user"
import {
    GetUpdatedMessagesRequest,
    GetUpdatedMessagesResponse,
    Message,
    MessageAttachment,
    MessageDirection,
    MessageTextType,
    MessageType,
    UpdatedMessage
} from "../../models/Dialogs/message"
import { Notification, NotificationPayload } from "../../models/notification"
import notificationTranslations from "../../locales/notification"
import toNotificationPayload from "../notifications/toNotificationPayload"
import getInternalId from "../notifications/getInternalId"
import { ArticleComment, ArticleCommentType } from "../../models/articleComment"
import { ArticleUserView } from "../../models/articleUserViews"
import {
    KnowledgeBaseArticleSubscriptions,
    KnowledgeBaseSubscriptionType,
    KnowledgeBaseSubscriptionValue
} from "../../models/knowledgeBaseSubscription"
import {
    KnowledgeBaseAccessType,
    KnowledgeBasePermission,
    KnowledgeBasePermittedAction
} from "../../models/knowledgeBasePermission"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleAnswerSlot,
    ArticleKind,
    ArticleStatusType,
    ArticleView,
    SearchArticlesHighlights,
    SearchArticlesScores
} from "../../models/article"
import { SlotContext, SlotDto, SlotKind, SlotType, SystemSlot } from "../../models/slot"
import { TabEntry } from "../../types/tabEntry"
import { OperatorStatus, OperatorStatusValue, SelectedOperatorStatus } from "../../models/operatorStatus"
import { Operator } from "../../models/operator"
import { SystemError } from "../../core/error"
import { Task, TaskStatus } from "../../models/task"
import { ChannelTypeString, SimpleChannel } from "../../models/channel"
import { ViewClient } from "../../permissions"
import {
    ApiDialogStatistic,
    Dialog,
    DialogAgent,
    DialogBadge,
    DialogClient,
    DialogPerson,
    DialogProject,
    IGetOperatorClientBadgesResponse,
    IGetOperatorClientResponse,
    IGetOperatorsListResponse,
    IGetUserChannelsResponse,
    ISearchOperatorsByCriterionResponse
} from "../../models/Dialogs/dialog"
import { SurveyForm } from "../../models/Dialogs/surveyForm"
import { permissionWeightMapper } from "../knowledgeBase/knowledgeBase"
import { TreeData } from "@atlaskit/tree"
import { DisplayTypeValue } from "../../models/survey"
import { GetSettingsWithProjectResponse } from "../../models/projectSettings"
import { ProjectUserValues } from "../../components/ProjectUserForm/ProjectUserForm"
import { BandwidthSetting } from "../../models/projectSettingsValues"
import { ChannelValuesBase } from "../../models/channelValues"

export const mockedTranslation = {
    i18n: {
        language: "ru",
        languages: ["ru", "en"]
    },
    t: (key: unknown) => key
}

export const mockedInitReactI18next = {
    type: "3rdParty",
    init: () => void 0
}

export const mockedi18n = {
    use: (_: unknown) => mockedi18n,
    init: (_: unknown) => mockedi18n
}

export const mockedProjects: Project[] = [
    {
        id: "test1",
        name: "TEST1",
        language: "ru",
        logo: "",
        big_logo: ""
    },
    {
        id: "test2",
        name: "TEST2",
        language: "en",
        logo: ""
    },
    {
        id: "test3",
        name: "TEST3",
        language: "ru",
        logo: "",
        big_logo: ""
    }
]

export const mockedDefaultProjectWithSettings: GetSettingsWithProjectResponse = {
    Project: {
        id: "test",
        name: "test",
        language: "ru",
        logo: "/api/dialogs/get_file/file/050/6aed2c12-bbc9-4c6c-aefc-6c1fb0b4a2a7-0bd8cab8-27ff-468c-aa4e-03ab3277f9f0.png"
    },
    Settings: {
        DialogTimeout: {
            FirstTimeout: {
                Value: 3000000
            },
            SecondTimeout: {
                Value: 3000000
            },
            FinishDialogTimeout: {
                Value: 3000000
            },
            DialogLifeTimeout: {
                Value: 60000000
            }
        },
        Operator: {
            Common: {
                AllowChatJoinRequests: false
            },
            ClientSurvey: {
                MainSurveyId: "1c8ec71a-879e-4d18-aa30-d9ad18d3c08d",
                ShortSurveyId: "88e957de-11d2-4174-a29b-5a60749ee1f0",
                PhoneNumberMask: "+7(999)-999-99-99"
            },
            DialogSurvey: {
                ChooseTopicToFinishDialogRequired: false,
                DialogTopicCatalogId: "classifier-b7382eae-4acc-480d-a98e-af77590e88ff",
                DialogProgramCatalogId: "classifier-b7382eae-4acc-480d-a98e-af77590e88ff",
                DialogSurveyId: "e252600e-ad99-417b-8b72-006a8b481bea"
            },
            KPI: {
                DialogTimerEnabled: true,
                GreenDialogTimerTimeout: 300000,
                YellowDialogTimerTimeout: 240000,
                DialogWaitingForClientAnswerTimerEnabled: true,
                DialogWaitingForClientAnswerTimeout: 60000,
                DialogWaitingForOperatorAnswerTimerEnabled: false,
                DialogWaitingForOperatorAnswerTimeout: 60000,
                DialogTimerTimeoutPlusEnabled: false,
                DialogTimerTimeoutCustomLabelEnabled: false
            },
            Workplace: {
                ShowDialogHistory: true,
                ForwardingMessage: true,
                RespondWithArticleEnabled: true,
                UseSpellChecker: true,
                UseEmoji: true,
                SetPreviousTopicsAfterFinishDialog: false,
                LoadHistoryByEmail: false,
                LoadHistoryByPhoneNumber: false
            },
            NewWorkplace: {
                DialogSurveyId: "e252600e",
                ClientSurveyId: "1c8ec71a-879e-4d18",
                CustomSections: {
                    Values: []
                },
                EnableSeparateNewAndInProgressDialogs: true,
                SendMessageActionsAsButtons: {
                    Hold: false
                },
                ClientsSectionName: ""
            },
            AutoGreeting: {
                OperatorAutoGreetingEnabled: false,
                OperatorAutoGreetingMale: ""
            },
            ResponseTemplate: {
                FastReplyTemplates: "",
                FastReplyHoldTemplates: { Values: [] },
                WaitingForClientResponseTimeout: 1,
                WaitingForClientResponseTimeoutMessages: { Values: [] },
                TriggerMessagesForRunClientHolding: { Values: [] },
                ClientHoldingTimeout: 1,
                ClientHoldingMessages: { Values: [] }
            },
            Hold: {
                AutoHoldingEnabled: true,
                DropDialogTimeout: 120000,
                FinishDialogWithoutClientResponseTimeout: 10800000
            },
            Notifications: {
                PushNotificationEnabled: true,
                PushNotificationVolumeLevel: 0.1,
                PushNotificationTimeout: 1
            },
            OutgoingDialog: {
                FinishOutgoingDialogAfterStart: false
            }
        },
        Report: {
            CommonReportTimeZone: 10800000,
            CommonReportSlots: { Values: [] }
        },
        Agent: {
            DefaultAgent: "initialmsgagent",
            DefaultAgentForOutgoingDialog: "routingagent",
            OperatorAgent: "routingagent",
            SystemEventsAgent: ""
        },
        System: {
            UpdateClientDataFromChannel: true,
            ChannelOnDialogPanel: true,
            OperatorQuestionaryId: "",
            DialogSwitchNotificationWebhook: "",
            SwitchToBotWindowTitle: "",
            SwitchToBotWindowMessage: "",
            DisableMarkupModal: true,
            HideAIAssistInChat: false,
            KnowledgeBaseConvertTemplate: {
                FileName: "crafttalk_export_template.dotx",
                FileUrl: "url.com"
            },
            EnableMessageSendBehaviour: false,
            AdditionalResendConfigDependsOnQueue: false,
            LogoHugeSizeChangeAllow: true,
            ColorSchemaChangeAllow: true,
            ColorSchema: {
                ColorSchemaUsing: true,
                ColorSchemaBaseColor: "#FF0000",
                ColorSchemaEditorColor: "",
                ColorSchemaLinkColor: ""
            },
            CustomMenus: { Values: [] },
            AutoPinMessagesEnabled: true,
            AutoPinMessagesTypes: "*",
            CustomPermissions: {
                CustomPermissionsAllow: false,
                Values: []
            },
            CustomMenus: { Values: [] }
        }
    }
}

export const mockMenu: Menu = {
    id: "test",
    title: "test",
    items: [
        {
            id: "dialogs",
            title: "menu-item-dialogs",
            role: ViewClient,
            submenus: []
        },
        {
            id: "testMenu1",
            title: "testMenu1",
            submenus: []
        },
        {
            id: "testMenu2",
            title: "testMenu2",
            submenus: []
        },
        {
            id: "testMenu3",
            title: "testMenu3",
            submenus: []
        }
    ]
}

export const mockedQueue: Queue = {
    Id: "test",
    TenantId: "test",
    Type: QueueType.Auto,
    Name: "test",
    CategoryId: "test",
    TaskSize: 0,
    Priority: 0,
    IndividualPriority: 0,
    IndividualTimeoutSettings: { TimeoutMSec: 0 },
    LastPickTime: 0,
    Sl: 0,
    Awt: {},
    FinishedDialogs: {},
    FinishedDialogsDaily: {},
    CSI: 0,
    OperatorsIds: [],
    OperatorsTasksCount: {},
    PendingTasks: [],
    PendingIndividualTasks: []
}

export const mockedUser: User = {
    Login: "test",
    FirstName: "Test1",
    LastName: "Test2",
    MaxDialogs: 1,
    Permissions: [],
    Locale: "ru",
    Projects: ["test1"],
    QuestionaryFields: [{ Value: "", SlotId: "sys_firstname" }],
    KeyCombination: "Ctrl+Enter"
}

export const mockedSimpleClient: DialogPerson = {
    IdInChannel: "e77a56dc-4bf6-4978-aedc-m5271ae14108",
    OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
    Firstname: "Firstname",
    Lastname: "Lastname"
}

export const mockedLegacyMessage: Message = {
    MsgId: "8907ab2f-e405-4cab-80d4-58e420c24f8b",
    MsgType: MessageType.Message,
    Direction: MessageDirection.Incoming,
    Text: "text",
    Actions: [],
    IdInChannel: "140107392",
    ChannelId: "telegram",
    ClientId: 0,
    OperatorName: "",
    DialogID: "1bd3ae35-62d4-43ac-ad80-53a7f40f4227",
    Context: [],
    Flags: [],
    IsServiceMessage: false,
    IsClientServiceMessage: false,
    Added: "2020-10-01T07:51:43.647+00:00",
    Meta: {
        OCRM_FIRSTNAME: "Test",
        OCRM_LASTNAME: "Test",
        OCRM_PATRNAME: "Test",
        routing_hint: "3",
        sys_phone: "79999999999",
        location: '{"lat":52.997178,"lon":36.065835}',
        channel_id: "telegram"
    },
    TextType: MessageTextType.Text
}

export const mockedMessage: UpdatedMessage = {
    Id: "8907ab2f-e405-4cab-80d4-58e420c24f8b",
    MessageType: MessageType.Message,
    Direction: MessageDirection.Incoming,
    Text: "text",
    Actions: [],
    Attachments: [],
    Sender: {
        Id: "test-client"
    },
    ChannelId: "test-face",
    Meta: {
        OCRM_FIRSTNAME: "Test",
        OCRM_LASTNAME: "Test",
        OCRM_PATRNAME: "Test",
        routing_hint: "3",
        sys_phone: "79999999999",
        location: '{"lat":52.997178,"lon":36.065835}',
        channel_id: "telegram"
    },
    DialogId: "1bd3ae35-62d4-43ac-ad80-53a7f40f4227",
    Context: [],
    AddedDate: 1689320916360,
    Status: 1,
    TextType: MessageTextType.Text
}

export const mockedMessages: UpdatedMessage[] = [
    mockedMessage,
    {
        ...mockedMessage,
        Id: "11" + mockedMessage.Id,
        Direction: MessageDirection.Outgoing,
        Text: "type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset",
        Sender: {
            Id: "test-operator"
        }
    },
    {
        ...mockedMessage,
        Id: "12" + mockedMessage.Id,
        Text: "Thank you!",
        Attachments: [
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.png",
                Size: 1024
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.jpg",
                Size: 1023
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.pdf",
                ContentType: "file",
                Name: "Book.pdf",
                Size: 8192
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.docx",
                ContentType: "image",
                Name: "Document.docx",
                Size: 20000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.xlsx",
                ContentType: "image",
                Name: "Price_table.xlsx",
                Size: 3500000000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.exe",
                ContentType: "file",
                Name: "Exe_file",
                Size: 1000000000000000
            }
        ]
    },
    {
        ...mockedMessage,
        Id: "12" + mockedMessage.Id,
        Text: "Thank you!",
        Attachments: [
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.png",
                Size: 1024
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.jpg",
                Size: 1023
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.pdf",
                ContentType: "file",
                Name: "Book.pdf",
                Size: 8192
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.docx",
                ContentType: "image",
                Name: "Document.docx",
                Size: 20000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.xlsx",
                ContentType: "image",
                Name: "Price_table.xlsx",
                Size: 3500000000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.exe",
                ContentType: "file",
                Name: "Exe_file",
                Size: 1000000000000000
            }
        ]
    },
    {
        ...mockedMessage,
        Id: "12" + mockedMessage.Id,
        Text: "Thank you!",
        Attachments: [
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.png",
                Size: 1024
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.jpg",
                Size: 1023
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.pdf",
                ContentType: "file",
                Name: "Book.pdf",
                Size: 8192
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.docx",
                ContentType: "image",
                Name: "Document.docx",
                Size: 20000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.xlsx",
                ContentType: "image",
                Name: "Price_table.xlsx",
                Size: 3500000000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.exe",
                ContentType: "file",
                Name: "Exe_file",
                Size: 1000000000000000
            }
        ]
    },
    {
        ...mockedMessage,
        Id: "12" + mockedMessage.Id,
        Text: "Thank you!",
        Attachments: [
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.png",
                Size: 1024
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.png",
                ContentType: "image",
                Name: "land_rover_PNG57.jpg",
                Size: 1023
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.pdf",
                ContentType: "file",
                Name: "Book.pdf",
                Size: 8192
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.docx",
                ContentType: "image",
                Name: "Document.docx",
                Size: 20000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.xlsx",
                ContentType: "image",
                Name: "Price_table.xlsx",
                Size: 3500000000
            },
            {
                Url: "https://pngimg.com/uploads/land_rover/land_rover_PNG57.exe",
                ContentType: "file",
                Name: "Exe_file",
                Size: 1000000000000000
            }
        ]
    }
]

export const mockedGetUpdatedMessagesResponse: GetUpdatedMessagesResponse = {
    Messages: mockedMessages.map(m => ({ Case: "Message", Fields: m })),
    HasMore: false
}

export const mockedGetUpdatedMessagesRequest: GetUpdatedMessagesRequest = {
    OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
    Count: 20,
    StartTime: 0,
    FromTodayDialogs: true,
    ProjectId: "test"
}

export const mockNotificationPayloads: NotificationPayload = {
    Id: getInternalId(),
    UserId: "",
    NeedApprove: false,
    Title: {
        Value: "Test notification",
        NeedLocalization: false
    },
    Description: {
        Value: "Common notification message. Just for test",
        NeedLocalization: false
    },
    Timestamp: 0,
    Priority: 0
}

export const mockNewsNotification: NotificationPayload[] = [
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "/project/base/knowledge-base/article/view/article-d703b847-52ad-43f1-84d6-ec6d8adbf847",
        Description: {
            Value: notificationTranslations.descriptionNewArticle,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        NeedApprove: true,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionArticleEdited,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionNewCommentAdded,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionNewsAdded,
            NeedLocalization: true
        }
    }
].map(toNotificationPayload)

export const mockNotifications: Notification[] = [
    {
        Id: "1",
        UserId: "",
        NeedApprove: false,
        Priority: 0,
        Type: "error",
        Title: {
            Value: "Test error notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Error notification message. Just for test",
            NeedLocalization: false
        },
        Timestamp: 0
    },
    {
        Id: "2",
        UserId: "",
        NeedApprove: false,
        Priority: 10,
        Type: "info",
        Title: {
            Value: "Test notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Common notification message. Just for test",
            NeedLocalization: false
        },
        Timestamp: 1
    },
    {
        Id: "3",
        UserId: "",
        NeedApprove: false,
        Priority: 0,
        Type: "message",
        Title: {
            Value: "Message notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Notification message description",
            NeedLocalization: false
        },
        Timestamp: 20
    },
    {
        Id: "4",
        UserId: "",
        NeedApprove: false,
        Priority: 1,
        Type: "message",
        Title: {
            Value: "Message notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Notification message description",
            NeedLocalization: false
        },
        Timestamp: 5
    }
]

export const mockArticleComments: ArticleComment[] = [
    {
        Id: "1",
        ArticleCode: "12",
        Type: ArticleCommentType.Comment,
        User: mockedUser,
        Message: {
            Text: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.'
        },
        AddedTimestamp: 0,
        ReplyCount: 3,
        ProjectId: "test"
    },
    {
        Id: "2",
        ArticleCode: "123",
        Type: ArticleCommentType.Comment,
        User: mockedUser,
        Message: {
            Text: 'Lorem Ipsum - это текст-"здесь рыбов нет". Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.'
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    },
    {
        Id: "3",
        ArticleCode: "321",
        Type: ArticleCommentType.Comment,
        User: mockedUser,
        Message: {
            Text: "В то время некий Безымянный Остров создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum."
        },
        AddedTimestamp: 0,
        ReplyCount: 1,
        ProjectId: "test"
    }
]

export const mockArticleCommentThread: ArticleComment[] = [
    {
        Id: "2",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: "looooooong text"
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    },
    {
        Id: "3",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: "KLFJIERUWEXCN<MXC<B"
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    },
    {
        Id: "4",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.'
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    }
]

export const simpleUser: SimpleUser = {
    Login: "test",
    FirstName: "FirstName",
    LastName: "LastName",
    Picture: ""
}

export const articleUserViews: ArticleUserView[] = [
    {
        Login: "test",
        ViewedAt: 1711420484604,
        ReadConfirmedAt: null
    },
    {
        Login: "test2",
        ViewedAt: 1711420484604,
        ReadConfirmedAt: 1711420484604
    }
]

export const mockedArticleSubscriptionValues: KnowledgeBaseSubscriptionValue[] = [
    {
        Type: KnowledgeBaseAccessType.All,
        SubscriptionType: KnowledgeBaseSubscriptionType.Notify,
        ProjectId: "test1",
        Value: ""
    },
    {
        Type: KnowledgeBaseAccessType.Login,
        SubscriptionType: KnowledgeBaseSubscriptionType.WithConfirmation,
        ProjectId: "test1",
        Value: "test_user"
    },
    {
        Type: KnowledgeBaseAccessType.Role,
        SubscriptionType: KnowledgeBaseSubscriptionType.WithConfirmation,
        ProjectId: "test1",
        Value: "test_role"
    }
]

export const mockedArticlePermissionValues: KnowledgeBasePermission[] = [
    {
        Type: KnowledgeBaseAccessType.All,
        Action: KnowledgeBasePermittedAction.Edit,
        ProjectId: "test1",
        Value: "",
        SearchValue: "",
        Weight: permissionWeightMapper(KnowledgeBaseAccessType.All, KnowledgeBasePermittedAction.Edit)
    },
    {
        Type: KnowledgeBaseAccessType.Login,
        Action: KnowledgeBasePermittedAction.View,
        ProjectId: "test1",
        Value: "test_user",
        SearchValue: "",
        Weight: permissionWeightMapper(KnowledgeBaseAccessType.Login, KnowledgeBasePermittedAction.View)
    }
]

export const mockedArticleSubscriptions: KnowledgeBaseArticleSubscriptions = {
    Id: "test123",
    ArticleCode: "test_article",
    Subscriptions: mockedArticleSubscriptionValues
}

export const mockedAnswers: ArticleAnswer[] = [
    {
        Id: "1",
        Text: "Test text 1",
        Slots: []
    },
    {
        Id: "2",
        Text: "Test text 2",
        Slots: [
            {
                SlotId: "22",
                Values: ["test value"]
            }
        ]
    }
]

export const mockedAnswerSlots: ArticleAnswerSlot[] = [
    {
        SlotId: "slot1",
        Values: []
    },
    {
        SlotId: "slot2",
        Values: ["value3", "value4"]
    }
]

export const mockedSlots: SlotDto[] = [
    {
        ProjectId: "test",
        Id: "slot1",
        Title: "First slot",
        ExternalId: "slot1",
        Type: SlotType.Text,
        Kind: SlotKind.Default,
        ModifiedData: 1616659854307,
        ModifiedUserLogin: "test"
    },
    {
        ProjectId: "test",
        Id: "slot2",
        Title: "Second slot",
        ExternalId: "slot1",
        Type: SlotType.Text,
        Kind: SlotKind.Default,
        ModifiedData: 1616659854307,
        ModifiedUserLogin: "test"
    }
]

export const mockedArticle: KnowledgeBaseArticle = {
    ProjectId: "test",
    Id: "test1",
    Kind: ArticleKind.Common,
    SymbolCode: "test1",
    Title: "Test1",
    Type: "Type",
    Status: ArticleStatusType.Draft,
    Questions: [{ Sentence: "Test question" }],
    Answers: [
        { Id: "a1", Text: "Answer1", Slots: [] },
        { Id: "a2", Text: "Answer2", Slots: [] }
    ],
    Expire: 0,
    PrevVersion: 637522566529658812,
    FirstVersion: 637522566450894286,
    ModifiedDate: 1616659854307,
    ModifiedUserLogin: "test",
    Permissions: [],
    PermissionsWithNested: false,
    Tags: [],
    Parameters: [],
    Expanded: false
}

export const minimalHtml =
    "<!DOCTYPE html>\n" +
    '<html lang="en">\n' +
    "  <head>\n" +
    '    <meta charset="utf-8">\n' +
    "    <title>title</title>\n" +
    '    <link rel="stylesheet" href="style.css">\n' +
    '    <script src="script.js"></script>\n' +
    "  </head>\n" +
    "  <body>\n" +
    "    Test content\n" +
    "  </body>\n" +
    "</html>"

export const mockTabEntries: TabEntry[] = [
    { key: "1", value: 1 },
    { key: "2", value: 2 }
]

export const mockedOperatorStatuses: OperatorStatus[] = [
    {
        Value: OperatorStatusValue.Online,
        Description: "online",
        Title: {
            Value: "menu:statuses.online",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "offline",
        Title: {
            Value: "menu:statuses.offline",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.DoNotRoute,
        Description: "donotroute",
        Title: {
            Value: "menu:statuses.invisible",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "lunch",
        Title: {
            Value: "menu:statuses.lunch",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "training",
        Title: {
            Value: "menu:statuses.training",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Online,
        Description: "custom-online",
        Title: {
            Value: "Custom Online",
            NeedLocalization: false
        }
    }
]

export const mockedSelectedOperatorStatus: SelectedOperatorStatus = {
    Value: OperatorStatusValue.Online,
    Description: "online",
    Title: {
        Value: "Ready to work",
        NeedLocalization: false
    },
    UpdatedAt: 0
}

export const mockedAllSelectedOperatorStatus = {
    online: mockedSelectedOperatorStatus,
    doNotRoute: {
        Value: OperatorStatusValue.DoNotRoute,
        Description: "donotroute",
        Title: {
            Value: "Do Not Route",
            NeedLocalization: false
        },
        UpdatedAt: 0
    },
    offline: {
        Value: OperatorStatusValue.Offline,
        Description: "offline",
        Title: {
            Value: "AFK",
            NeedLocalization: false
        },
        UpdatedAt: 0
    }
}

export const mockedTreeItemData = {
    title: "Test article",
    symbolCode: "test_code",
    parentCategoryCode: "test_parent_category_code"
}

export const jsxCodeSample = `(function someDemo() {
  var test = "Hello World!";
  console.log(test);
})();

return () => <App />;
`

export const mockedTasks: Task[] = [
    {
        Id: "task1",
        Type: "Conversation",
        TenantId: "test",
        Status: TaskStatus.Assigned,
        CreatedAt: Date.now() - 136000,
        AssignedAt: Date.now() - 100789,
        Channel: {
            Id: "webchat1",
            Type: ChannelTypeString.WebChat,
            Title: "WebChat for clients"
        },
        Client: {
            Id: "client1",
            FirstName: "Stepan",
            LastName: "Galagan"
        }
    },
    {
        Id: "task2",
        Type: "Conversation",
        TenantId: "test",
        Status: TaskStatus.Pending,
        CreatedAt: Date.now() - 34543450,
        AssignedAt: Date.now() - 34243450,
        Channel: {
            Id: "telegram1",
            Type: ChannelTypeString.Telegram,
            Title: "Messenger"
        },
        Client: {
            Id: "client2",
            FirstName: "Mark",
            LastName: "Mark"
        }
    },
    {
        Id: "task3",
        Type: "Call",
        TenantId: "test",
        Status: TaskStatus.Pending,
        CreatedAt: Date.now() - 63789,
        AssignedAt: Date.now() - 53789,
        Channel: {
            Id: "phone1",
            Type: ChannelTypeString.Phone,
            Title: "Private line"
        },
        Client: {
            Id: "client3",
            FirstName: "Yulia",
            LastName: "Op"
        }
    }
]

export const mockedOperator: Operator = {
    Id: "1",
    FirstName: "TestFirstName",
    LastName: "TestLastName",
    Status: mockedSelectedOperatorStatus,
    Capacity: 0,
    MaxCapacity: 10,
    Tasks: [mockedTasks[0]],
    TenantsQueues: []
}

export const mockedArticleViews: ArticleView[] = [
    {
        ProjectId: "test",
        Id: "1fd43056-a439-4c73-b319-5a2c1b5eed13",
        SymbolCode: "kp_000327",
        Title: "Регистрация установления отцовства. Где оформить Куда обратиться Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a1",
                Text: "Где оформить Куда обратиться ВАРИАНТ 2.",
                Slots: []
            }
        ],
        ModifiedDate: 1616659854307
    },
    {
        ProjectId: "test",
        Id: "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a",
        SymbolCode: "kp_003050",
        Title: "Электронный сертификат. Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a2",
                Text: "РЕЧЕВОЙ МОДУЛЬ: Оформить сертификат можно в Территориальных центрах социального.",
                Slots: []
            }
        ],
        ModifiedDate: 1616662375529
    },
    {
        ProjectId: "test",
        Id: "77e57ed5-10df-431f-a938-c61bd38c5f5a",
        SymbolCode: "kp_006433",
        Title: "Паспорт болельщика. Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a2",
                Text: "Паспорт болельщика.",
                Slots: []
            }
        ],
        ModifiedDate: 1616676609122
    }
]

export const mockedSearchArticlesHighlights: SearchArticlesHighlights = {
    "1fd43056-a439-4c73-b319-5a2c1b5eed13": {
        "answers.answer": ["Где <em>оформить</em> Куда обратиться ВАРИАНТ 2."],
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": ["Где <em>оформить</em>?"]
    },
    "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a": {
        "answers.answer": ["РЕЧЕВОЙ МОДУЛЬ: <em>Оформить</em> сертификат можно в Территориальных центрах социального"],
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": ["Где <em>оформить</em> Куда обратиться"]
    },
    "77e57ed5-10df-431f-a938-c61bd38c5f5a": {
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": [
            "Где <em>оформить</em> паспорт болельщика на кубок конфедераций?",
            "Где <em>оформить</em> fan id (фан ай ди)?"
        ]
    }
}

export const mockedSearchArticlesScores: SearchArticlesScores = {
    "1fd43056-a439-4c73-b319-5a2c1b5eed13": 14.140487,
    "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a": 13.037295,
    "77e57ed5-10df-431f-a938-c61bd38c5f5a": 12.424701
}

export const mockedSlot: SlotDto = {
    Id: "test",
    ExternalId: "test-id",
    ProjectId: "string",
    Title: "Title",
    Type: SlotType.Dictionary,
    Kind: SlotKind.Default,
    ModifiedData: 0,
    ModifiedUserLogin: "test",
    Domain: [
        {
            Id: "test",
            Title: "",
            Synonyms: [
                {
                    Id: "6972351b-cc44-47d2-9e7b-cfefdaa3b99s",
                    Value: "synonym1"
                },
                {
                    Id: "6972351b-cc94-47d2-9e7b-cfefdaa3b99a",
                    Value: "synonym2"
                }
            ]
        }
    ]
}

export const mockedSystemError: SystemError = {
    error: "Test error",
    contextKey: "some:key"
}

export const mockedDialogStat: ApiDialogStatistic = {
    Dispatched: 1688971377642
}

export const mockedNotification: NotificationPayload = {
    Id: "notification1",
    UserId: "test",
    Timestamp: Date.now(),
    Priority: 0,
    NeedApprove: false,
    Title: {
        NeedLocalization: false,
        Value: "Notification Info"
    }
}

export const mockedSimpleChannel: SimpleChannel = {
    Id: "webchat-common",
    Type: ChannelTypeString.WebChat,
    Title: "Webchat Common"
}

export const mockedDialogClient: DialogClient = {
    OmniUserId: "6972351b-cc44-47d2-9e7b-cfefdaa3b99s",
    IdInChannel: "76dsgdh33-cc44-54h3g-9e7b-vdbfdf435553",
    Firstname: "Viktor",
    Lastname: "Zakharov"
}

export const mockedDialogProject: DialogProject[] = [
    {
        Id: "test-project",
        Title: "text of the printing and typesetting industry. Lorem Ipsum has been the"
    },
    {
        Id: "test_dfkjdslfhsdkjghlfdkagdl",
        Title: "YYEW",
        Picture: "https://static.tildacdn.com/tild3631-3634-4033-a132-623661386137/svg02_1_8.svg"
    }
]

export const mockedDialogOperator: DialogPerson[] = [
    {
        IdInChannel: "e77a56dc-4bf6-4978-aedc-m5271ae14108",
        OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
        Lastname: "Main",
        Firstname: "Person"
    },
    {
        IdInChannel: "e77a56dc-4bf6-4978-aedc-m5271ae14108",
        OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
        Lastname: "Orlov",
        Firstname: "Dmitrii"
    },
    {
        IdInChannel: "e77a56dc-4bf6-4978-aedc-m5271ae14108",
        OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
        Firstname: "Alex",
        Lastname: "Alexx",
        Picture: "https://static.tildacdn.com/tild3265-3036-4764-b961-643539353661/vk.svg"
    },
    {
        IdInChannel: "e77a56dc-4bf6-4978-aedc-m5271ae14108",
        OmniUserId: "9cd15c08-1618-3f42-95d5-7b3d051eaed4",
        Firstname: "Ivan",
        Lastname: "Zakhaev"
    },
    {
        IdInChannel: "a45b23ef-8c12-43d9-ae67-zb7845cf9012",
        OmniUserId: "b78c34df-21a9-4c67-98e5-cd23e4567890",
        Lastname: "Johnson",
        Firstname: "Emily"
    },
    {
        IdInChannel: "f32e89ab-6543-21cd-ae78-cf4567de9012",
        OmniUserId: "d45e67bc-8765-43ab-9102-efg123456789",
        Lastname: "Smith",
        Firstname: "Michael"
    },
    {
        IdInChannel: "c76d90ef-1245-6789-bc34-az5678910234",
        OmniUserId: "f12a34bc-5678-4321-90de-ijkl56789012",
        Lastname: "Taylor",
        Firstname: "Sophia"
    },
    {
        IdInChannel: "h78i90jk-5678-4321-lmno-987pqrst2345",
        OmniUserId: "u89v23wx-4567-8901-yzab-cdef45678901",
        Lastname: "Chen",
        Firstname: "David"
    }
]

export const mockedDialogBadge: DialogBadge = {
    Id: "test-dialog-1",
    Client: mockedDialogOperator[1],
    Preview:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
    Channel: mockedSimpleChannel,
    Project: {
        Id: "test",
        Title: "Test Project"
    },
    UnreadMessages: 43,
    IsHoldEnabled: false,
    Stat: {
        Routed: 0,
        LastIncomingMessageSent: 0,
        LastOutgoingMessageSent: 0
    }
}

export const mockedDialogBadges: DialogBadge[] = [
    mockedDialogBadge,
    {
        ...mockedDialogBadge,
        Id: "test-dialog-2",
        Client: mockedDialogOperator[0],
        Project: mockedDialogProject[1]
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-3",
        Client: mockedDialogOperator[1],
        UnreadMessages: 10
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-4",
        Client: mockedDialogOperator[2],
        UnreadMessages: 5
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-5",
        Client: mockedDialogOperator[3],
        Project: mockedDialogProject[0]
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-6",
        Client: mockedDialogOperator[4],
        UnreadMessages: 10,
        IsReplied: true
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-7",
        Client: mockedDialogOperator[5],
        UnreadMessages: 15,
        IsReplied: true
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-8",
        Client: mockedDialogOperator[6],
        Project: mockedDialogProject[1],
        IsReplied: true
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-9",
        Client: mockedDialogOperator[7],
        UnreadMessages: 8,
        IsReplied: true
    },
    {
        ...mockedDialogBadge,
        Id: "test-dialog-3",
        Client: mockedDialogOperator[3],
        UnreadMessages: 15,
        IsHoldEnabled: true
    }
]

export const mockedActiveDialogsCount = 10

export const mockedDialogAgent: DialogAgent = {
    Id: "routingagent",
    Name: "Routing Agent"
}

export const mockedSlotContext: SlotContext = {
    FilledSlots: [
        {
            Id: SystemSlot.FirstName,
            Value: "Ivan"
        },
        {
            Id: SystemSlot.LastName,
            Value: "Ivanov"
        },
        {
            Id: SystemSlot.MiddleName,
            Value: ""
        },
        {
            Id: SystemSlot.Contract,
            Value: ""
        },
        {
            Id: SystemSlot.BirthDate,
            Value: ""
        },
        {
            Id: SystemSlot.Email,
            Value: ""
        },
        {
            Id: SystemSlot.Phone,
            Value: "77779387233"
        },
        {
            Id: SystemSlot.OmniUserId,
            Value: "5a06c6e7-ed1c-49c5-b0e7-9d5c6e6d406a"
        },
        {
            Id: SystemSlot.ExtUserId,
            Value: ""
        }
    ]
}

export const mockedDialog: Dialog = {
    Id: "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a",
    Client: mockedDialogClient,
    Project: mockedDialogProject[0],
    Channel: mockedSimpleChannel,
    Operator: mockedDialogOperator[0],
    Agent: mockedDialogAgent,
    Stat: mockedDialogStat,
    SlotContext: mockedSlotContext
}

export const mockedMessageAttachments: MessageAttachment[] = [
    { Url: "testUrl1", Size: 0, Name: "testName1", ContentType: "png" },
    { Url: "testUrl2", Size: 0, Name: "testName2", ContentType: "jpg" }
]

export const mockedSurveyForm: SurveyForm = {
    Id: "client-survey-1",
    Title: "Client survey",
    Fields: [
        {
            Id: "1",
            Slot: {
                SlotId: SystemSlot.FirstName,
                Title: "First name",
                Type: SlotType.Text,
                Domain: [],
                DisplayType: DisplayTypeValue.Auto,
                ReadOnly: false,
                IncreasedSize: false
            }
        },
        {
            Id: "2",
            Slot: {
                SlotId: SystemSlot.LastName,
                Title: "Last name",
                Type: SlotType.Text,
                Domain: [],
                DisplayType: DisplayTypeValue.Auto,
                ReadOnly: false,
                IncreasedSize: false
            }
        },
        {
            Id: "3",
            Slot: {
                SlotId: SystemSlot.MiddleName,
                Title: "Middle name",
                Type: SlotType.Text,
                Domain: [],
                DisplayType: DisplayTypeValue.Auto,
                ReadOnly: false,
                IncreasedSize: false
            }
        }
    ]
}

export const mockedOperatorClientsSectionTitle = "Clients"

export const mockedOperatorClientBadge: IGetOperatorClientBadgesResponse = {
    OmniUserId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e68",
    Firstname: "Иванов",
    Lastname: "Никита",
    Middlename: "Александрович",
    ProjectId: "test"
}

export const mockedOmniuserByChannelId = "b3d08ea9-8664-4f92-8f29-04a4e2fd5e69"

export const mockedOperatorClientBadges: IGetOperatorClientBadgesResponse[] = [
    mockedOperatorClientBadge,
    {
        OmniUserId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e69",
        Firstname: "Петров",
        Lastname: "Игорь",
        Middlename: "Сергеевич",
        ProjectId: "test"
    },
    {
        OmniUserId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e60",
        Firstname: "Сидоров",
        Lastname: "Михаил",
        Middlename: "Анатольевич",
        ProjectId: "test"
    }
]

export const mockedSearchOperatorByCriterion: ISearchOperatorsByCriterionResponse = {
    OperatorId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e68",
    Firstname: "Иванов",
    Lastname: "Никита",
    Middlename: "Александрович",
    Login: "",
    RoleId: ""
}

export const mockedSearchOperatorsByCriterion: ISearchOperatorsByCriterionResponse[] = [
    mockedSearchOperatorByCriterion,
    {
        OperatorId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e69",
        Firstname: "Петров",
        Lastname: "Игорь",
        Middlename: "Сергеевич",
        Login: "",
        RoleId: ""
    },
    {
        OperatorId: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e60",
        Firstname: "Сидоров",
        Lastname: "Михаил",
        Middlename: "Анатольевич",
        Login: "",
        RoleId: ""
    }
]

export const mockedOperatorClient: IGetOperatorClientResponse = {
    Id: "b3d08ea9-8664-4f92-8f29-04a4e2fd5e68",
    Project: {
        Id: "test",
        Title: "test",
        Picture: "https://static.tildacdn.com/tild3631-3634-4033-a132-623661386137/svg02_1_8.svg"
    },
    Firstname: "Petrov",
    Lastname: "Petrov",
    Middlename: "Petrov",
    //ActiveDialogId: "6a3cc809-b07c-4a35-834b-36c38d46b2a6",
    ActiveDialogId: "",
    Picture: "https://static.tildacdn.com/tild3631-3634-4033-a132-623661386137/svg02_1_8.svg",
    SlotContext: mockedSlotContext
}

export const mockedOperatorItem: IGetOperatorsListResponse = {
    OmniUserId: "11732e33-2214-43f7-9f19-e2417df3ba79",
    Firstname: "Operator 1",
    Lastname: "Operator 1",
    Middlename: "Operator 1"
}

export const mockedOperatorsList: IGetOperatorsListResponse[] = [
    mockedOperatorItem,
    {
        OmniUserId: "11732e33-2214-43f7-9f19-e2417df3ba78",
        Firstname: "Operator 2",
        Lastname: "Operator 2",
        Middlename: "Operator 2"
    },
    {
        OmniUserId: "11732e33-2214-43f7-9f19-e2417df3ba77",
        Firstname: "Operator 3",
        Lastname: "Operator 3",
        Middlename: "Operator 3"
    }
]

export const mockedArticleFavoriteBlock: TreeData = {
    rootId: "c29f5778-0d7e-42a8-986a-b6800ce6a3bb",
    items: {
        "c29f5778-0d7e-42a8-986a-b6800ce6a3bb": {
            id: "c29f5778-0d7e-42a8-986a-b6800ce6a3bb",
            children: ["article-e4e442c1-e945-4ef3-a0b8-508b0a9e9d54", "article-f431db8b-5407-47b6-9668-ff3af3d1c55d"],
            hasChildren: true,
            isExpanded: true,
            isChildrenLoading: false,
            data: {
                title: "Избранное",
                symbolCode: "root",
                catalogCode: "c29f5778-0d7e-42a8-986a-b6800ce6a3bb"
            }
        },
        "article-e4e442c1-e945-4ef3-a0b8-508b0a9e9d54": {
            id: "article-e4e442c1-e945-4ef3-a0b8-508b0a9e9d54",
            children: [],
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: {
                title: "df",
                symbolCode: "root",
                catalogCode: "article-e4e442c1-e945-4ef3-a0b8-508b0a9e9d54",
                parentId: "c29f5778-0d7e-42a8-986a-b6800ce6a3bb"
            }
        },
        "article-f431db8b-5407-47b6-9668-ff3af3d1c55d": {
            id: "article-f431db8b-5407-47b6-9668-ff3af3d1c55d",
            children: [],
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: {
                title: "testFavrote",
                symbolCode: "root",
                catalogCode: "article-f431db8b-5407-47b6-9668-ff3af3d1c55d",
                parentId: "c29f5778-0d7e-42a8-986a-b6800ce6a3bb"
            }
        }
    }
}

export const mockedBandwidthSettingsGroup = {
    chats: "Чаты",
    email: "Email",
    voice: "Голос"
}

export const mockedProjectUserValues: ProjectUserValues[] = [
    {
        roleId: "role-id",
        permissions: {},
        queues: {},
        maxDialogs: 5,
        bandwidth: {
            bandwidthSetting: BandwidthSetting.DefaultSetting
        }
    },
    {
        roleId: "role-id",
        permissions: {},
        queues: {},
        maxDialogs: 5,
        bandwidth: {
            bandwidthSetting: BandwidthSetting.Limits,
            bandwidthLimits: {
                chats: 2,
                email: 1,
                voice: 3
            }
        }
    }
]

export const mockedChannelBaseValues: ChannelValuesBase = {
    Title: "Title",
    BotSettings: { Id: "", Token: "" },
    WebHook: "",
    PreprocessorWebHook: "",
    Params: {},
    ServerAddress: "",
    BandwidthSettingsValues: {
        IncludeChannelInGroup: false,
        Group: ""
    }
}

export const mockedUserChannels: IGetUserChannelsResponse[] = [
    {
        channel_id: "channel_22edba0",
        channel_user_id: "694912360",
        omni_user_id: "ef7ce4fe-d95b-4a36-97a1-6a67f0c2208a",
        customer_id: "test",
        created_at: 1711371925621
    },
    {
        channel_id: "webchat_test",
        channel_user_id: "4e9cc45e-5e6a-4255-811b-9d00253d763b",
        omni_user_id: "ef7ce4fe-d95b-4a36-97a1-6a67f0c2208a",
        customer_id: "test",
        created_at: 1711355826759
    }
]

export const mockedCatalog: TreeData = {
    rootId: "test1",
    items: {
        "1": {
            id: "1",
            children: [],
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: {
                title: "hello world, great",
                symbolCode: "root1",
                catalogCode: "article-root1",
                parentId: "test1"
            }
        },
        "2": {
            id: "2",
            children: [],
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: {
                title: "goodbye my friend, its over",
                symbolCode: "root2",
                catalogCode: "article-root2",
                parentId: "test1"
            }
        },
        "3": {
            id: "3",
            children: [],
            hasChildren: false,
            isExpanded: false,
            isChildrenLoading: false,
            data: {
                title: "sample text! some text for you",
                symbolCode: "root3",
                catalogCode: "article-root3",
                parentId: "test1"
            }
        }
    }
}
