import React, { Dispatch, SetStateAction, useCallback, useState } from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./ArticleActionButtons.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { dialogsApi, useSendIntentMutation } from "../../api/controllers/dialogs"
import { useAppSelector } from "../../store/store"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import {
    selectCreateDialogOutgoingData,
    selectDialogId,
    selectLastMessageChannel,
    selectOperatorClientId
} from "../../store/dialogs/selectors"
import IconButton from "../IconButton/IconButton"
import { faPaperPlane, faPlus } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { selectCurrentAnswer } from "../../store/knowledgeBase/selectors"
import { testId } from "../../utility/tests/testId"
import cn from "classnames"
import { addToMessageInputText, closeKnowledgeBaseSidebar } from "../../store/dialogs/actions"
import { selectSlots } from "../../store/slots/selectors"
import { FilledSlot, FillingSlot } from "../../models/slot"
import { fillSlots } from "../../utility/common/fillSlots"
import { SlotsToFillStateProps } from "../Article/Article"
import { selectOperatorStatusState } from "../../store/userOperator/selectors"
import { OperatorStatusValue } from "../../models/operatorStatus"

const tNamespace = "knowledgeBase:"

export interface ArticleActionButtonsProps extends ClassProps {
    symbolCode: string
    setSlotsToFillState?: Dispatch<SetStateAction<SlotsToFillStateProps>>
    slotsToFill: FillingSlot[]
    isPublished: boolean
}

const ArticleActionButtons: React.FC<ArticleActionButtonsProps> = props => {
    const { t } = useTranslation()

    const { symbolCode, isPublished, setSlotsToFillState, slotsToFill } = props

    const dispatch = useDispatch()

    const [sendIntentMutation] = useSendIntentMutation()
    const projectId = useAppSelector(selectCurrentProjectId)

    const selectedDialogId = useAppSelector(selectDialogId)
    const strictDialogId = selectedDialogId ?? ""
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(strictDialogId))

    const currentClientId = useSelector(selectOperatorClientId)
    const { data: clientData } = useAppSelector(
        dialogsApi.endpoints.getOperatorClient.select({ projectId: projectId ?? "", omniUserId: currentClientId ?? "" })
    )

    const lastMessageChannel = useSelector(selectLastMessageChannel)
    const currentAnswer = useSelector(selectCurrentAnswer)
    const slots = useSelector(selectSlots)
    const dialogCreateOutgoingData = useSelector(selectCreateDialogOutgoingData)

    const [isNeedToFillOrEdit, setIsNeedToFillOrEdit] = useState(true)

    const operatorStatusState = useAppSelector(selectOperatorStatusState)
    const isOperatorOffline = operatorStatusState.data?.Value === OperatorStatusValue.Offline

    const handleFillSlots = (slotIds: string[], filledSlots: FilledSlot[]) => {
        const slotsToFill: FillingSlot[] = []
        for (const slotId of slotIds) {
            const slot = slots.find(slot => slot.ExternalId === slotId)

            if (slot) {
                slotsToFill.push({
                    Id: slotId,
                    Title: slot.Title ?? slotId,
                    Value: filledSlots.find(slot => slot.Id === slotId)?.Value
                })
            }
        }

        if (setSlotsToFillState) {
            setSlotsToFillState({
                selectedDialogId: selectedDialogId ?? "",
                selectedClientId: currentClientId ?? "",
                slotsToFill
            })
        }
    }

    const isHandleFillingSlot = (slotsInArticle: string[]): boolean => {
        const filledSlots = (dialogData ?? clientData)?.SlotContext.FilledSlots ?? []

        if (slotsInArticle && slots && isNeedToFillOrEdit) {
            handleFillSlots(slotsInArticle, filledSlots)
            setIsNeedToFillOrEdit(false)
            return true
        }

        return false
    }

    const getSlotsInArticle = (text: string): string[] => {
        return Array.from(new Set(text.match(/{{[a-zA-Z0-9_-]*}}/g)?.map(slot => slot.slice(2, -2))))
    }

    const handleSendArticle = useCallback(() => {
        if (projectId) {
            const articleText = currentAnswer?.Text ?? ""
            const slotsInArticle = getSlotsInArticle(articleText)
            if (slotsInArticle.length && isHandleFillingSlot(slotsInArticle)) {
                return
            }

            if (dialogData && dialogData.Channel) {
                sendIntentMutation({
                    OmniUserId: dialogData.Client.OmniUserId,
                    ChannelId: dialogData.Channel.Id,
                    StartNewDialog: false,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: dialogData.Project.Id
                })
            } else if (clientData && lastMessageChannel) {
                sendIntentMutation({
                    OmniUserId: clientData.Id,
                    ChannelId: lastMessageChannel.Id,
                    StartNewDialog: true,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: clientData.Project.Id,
                    UpdatedSlots: slotsToFill.length
                        ? slotsToFill.map(slot => ({ Id: slot.Id, Value: slot.Value ?? "" }))
                        : []
                })
                setSlotsToFillState &&
                    setSlotsToFillState({ selectedClientId: "", selectedDialogId: "", slotsToFill: [] })
            } else if (dialogCreateOutgoingData) {
                sendIntentMutation({
                    ChannelId: dialogCreateOutgoingData.channel.Id,
                    ChannelUserId: dialogCreateOutgoingData.channelUserId,
                    StartNewDialog: true,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: projectId
                })
            }

            dispatch(closeKnowledgeBaseSidebar())
        }
    }, [
        dialogData,
        symbolCode,
        projectId,
        dispatch,
        sendIntentMutation,
        currentAnswer,
        isNeedToFillOrEdit,
        clientData,
        lastMessageChannel,
        slotsToFill
    ])

    const handleEditArticle = useCallback(() => {
        if (selectedDialogId || currentClientId) {
            const articleText = currentAnswer?.Text ?? ""
            let formattedText
            const slotsInArticle = getSlotsInArticle(articleText)
            if (slotsInArticle.length) {
                if (isHandleFillingSlot(slotsInArticle)) {
                    return
                }

                const filledSlots = dialogData
                    ? dialogData.SlotContext?.FilledSlots ?? []
                    : slotsToFill.map(slot => ({ Id: slot.Id, Value: slot.Value ?? "" })) ?? []

                formattedText = fillSlots(articleText, dialogData?.Operator, filledSlots)
            } else {
                console.warn("Error with get dialog data, full text was pasted")
                formattedText = articleText
            }

            dispatch(addToMessageInputText(formattedText))
            dispatch(closeKnowledgeBaseSidebar())
        }
    }, [currentAnswer, selectedDialogId, dispatch, dialogData, currentClientId, isNeedToFillOrEdit, slotsToFill])

    return (
        <div className={styles.articleActions} data-testid={testId.articleActions}>
            <IconButton
                icon={faPlus}
                className={cn(styles.articleActions_button, styles.articleActions_pasteArticleButton)}
                onClick={handleEditArticle}
                variant="primary"
            >
                {t(`${tNamespace}article-actions.edit-article-button`)}
            </IconButton>
            <IconButton
                icon={faPaperPlane}
                className={styles.articleActions_button}
                onClick={handleSendArticle}
                variant="primary"
                disabled={!isPublished || isOperatorOffline}
            >
                {t(`${tNamespace}article-actions.send`)}
            </IconButton>
        </div>
    )
}

export default ArticleActionButtons
