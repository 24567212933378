import {
    EditedElement,
    EditEnum,
    GetUpdatedMessagesRequest,
    isApiMessage,
    MessageAttachment,
    SendMessageRequest,
    Sticker
} from "../../models/Dialogs/message"
import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import { dialogsApi } from "../../api/controllers/dialogs"
import { convertRequestToResponse } from "../message/convertRequestToResponse"

export const updateDialogMessageEditType = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    messageIdToDelete: string,
    messageIdToCreate: string,
    editType: EditEnum,
    attachments: MessageAttachment[] = [],
    text?: string,
    sticker?: Sticker
) => {
    return dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const index = draft.Messages.findIndex(message => message.Fields.Id === messageIdToDelete)
            if (index !== -1) {
                const storedMessage = draft.Messages[index]
                if (isApiMessage(storedMessage)) {
                    draft.Messages.splice(index, 1)
                    switch (editType) {
                        case EditEnum.Deleted:
                            draft.Messages.push({
                                Case: storedMessage.Case,
                                Fields: {
                                    ...storedMessage.Fields,
                                    Id: messageIdToCreate,
                                    EditType: editType
                                }
                            })
                            break
                        case EditEnum.Edited:
                            const strictText = text ? text : ""
                            draft.Messages.push({
                                ...storedMessage,
                                Fields: {
                                    ...storedMessage.Fields,
                                    Id: messageIdToCreate,
                                    Text: strictText,
                                    EditType: editType,
                                    Attachments: attachments,
                                    Sticker: sticker
                                }
                            })
                            break
                        default:
                            break
                    }
                }
            }
        })
    )
}

export const updateMessageEditHistory = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    id: string,
    attachments: MessageAttachment[] = [],
    text?: string,
    sticker?: Sticker
) => {
    return dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const index = draft.Messages.findIndex(message => message.Fields.Id === id)
            if (index !== -1) {
                const storedMessage = draft.Messages[index]
                if (isApiMessage(storedMessage)) {
                    draft.Messages.splice(index, 1)
                    const strictText = text ? text : ""
                    const incomingEditRecord = {
                        text: strictText,
                        attachments: attachments,
                        sticker: sticker
                    }
                    const storedEditRecord = {
                        text: storedMessage.Fields.Text,
                        attachments: storedMessage.Fields.Attachments,
                        sticker: storedMessage.Fields.Sticker
                    }
                    const EditedHistory: EditedElement[] = storedMessage.EditedHistory
                        ? [...storedMessage.EditedHistory, incomingEditRecord]
                        : [storedEditRecord, incomingEditRecord]
                    draft.Messages.push({
                        ...storedMessage,
                        Fields: {
                            ...storedMessage.Fields
                        },
                        EditedHistory: EditedHistory
                    })
                }
            }
        })
    )
}

export const updateDialogMessageQuery = (
    dispatch: ThunkDispatch<never, never, AnyAction>,
    currentMessagePagination: GetUpdatedMessagesRequest,
    messageIdToDelete: string,
    messageIdToCreate: string,
    updateMessage: SendMessageRequest,
    operatorId: string
) => {
    return dispatch(
        dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, draft => {
            const index = draft.Messages.findIndex(message => message.Fields.Id === messageIdToDelete)
            if (index !== -1) {
                const draft1 = draft.Messages[index]
                if (isApiMessage(draft1) && updateMessage && operatorId) {
                    draft.Messages.splice(index, 1)
                    draft.Messages.push(convertRequestToResponse(updateMessage, operatorId, messageIdToCreate))
                }
            }
        })
    )
}
