import { ApiMessage } from "../../models/Dialogs/message"
import store, { Store } from "../../store/store"
import { IHub } from "../interfaces/IHub"
import { push } from "connected-react-router"
import { pushNotification } from "../../utility/notifications/pushNotifications"
import { actions } from "../../store/dialogs/slice"
import { DialogsHubEvents } from "../../models/Dialogs/hub"
import { createTabNotification } from "../../utility/common/TabNotifications"
import { MESSAGES_PER_PAGE } from "../../utility/dialogs/constant"

export const playNotificationSound = () => {
    const audioNode = new Audio("/Assets/sound/notify.mp3")

    const pushNotificationVolumeLevel =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationVolumeLevel

    audioNode.volume = pushNotificationVolumeLevel || 0.5
    audioNode.currentTime = 0
    audioNode.play().catch(e => console.error("Failed to play sound", e))
}

export const handleEvents = (events: DialogsHubEvents, eventHandler: IHub["registerEvent"]) =>
    Object.entries(events).forEach(([eventName, callback]) => {
        eventHandler(eventName, callback)
    })

export const isStarterMessage = (message: ApiMessage) => {
    return message.Fields.Text === "/start"
}

export const isCurrentUserSender = (message: ApiMessage, store: Store) => {
    const currentOperator = store.getState().users.currentUser
    return (
        (message.Fields.Sender.Id === currentOperator.data?.Login ||
            message.Fields.Sender.Id === `DEFAULT___${currentOperator.data?.Login}`) &&
        message.Fields.Meta?.initialized_from_timer_service !== "True"
    )
}

export const applyPushNotificationIfEnabled = ({
    title,
    body,
    projectId,
    dialogId,
    clientId,
    dispatch
}: {
    title: string
    body: string
    dialogId: string
    projectId: string
    clientId: string
    dispatch: Store["dispatch"]
}) => {
    const isPushNotificationEnabled =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationEnabled

    if (!isPushNotificationEnabled) {
        return null
    }

    let pushNotificationTimeout = store.getState().project.settings?.Operator?.Notifications?.PushNotificationTimeout
    if (typeof pushNotificationTimeout === "number") {
        pushNotificationTimeout = pushNotificationTimeout * 1000
    }

    return pushNotification({
        title,
        body,
        onClick() {
            dispatch(push(`/project/${projectId}/dialogs`))
            dispatch(actions.setCurrentDialogId(dialogId))
            dispatch(actions.unsetCurrentOperatorClientId())

            window.focus()

            dispatch(
                actions.setMessagesPaginationState({
                    OmniUserId: clientId,
                    StartTime: 0,
                    Count: MESSAGES_PER_PAGE,
                    FromTodayDialogs: true,
                    ProjectId: projectId
                })
            )
        },
        timeout: pushNotificationTimeout
    })
}

export const notifyOperator = (
    pushConfig: Parameters<typeof applyPushNotificationIfEnabled>[0],
    tabConfig: Parameters<typeof createTabNotification>[0]
) => {
    playNotificationSound()
    applyPushNotificationIfEnabled(pushConfig)
    createTabNotification(tabConfig)
}
