import { Dispatch } from "../../utility/common/storeHelper"
import { actions as dialogActions, actions } from "./slice"
import { dialogDraftsLocalStoreDriver } from "../../api/controllers/dialogs"
import store from "../store"
import { scrollDownMessageInput } from "../../utility/dialogs/scrollDownMessageInput"
import trim from "lodash/trim"

export function backToDialogList() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
    }
}

export function clearDialogsState() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setSearchCriterion(""))
    }
}

export function createDialog() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.createDialog())
    }
}

export const closeKnowledgeBaseSidebar = () => {
    return (dispatch: Dispatch) => {
        dispatch(dialogActions.closeArticleSidebar())
        dispatch(dialogActions.closeKnowledgeBaseSidebar())
    }
}

export const setMessageInputText = (text: string) => {
    return (dispatch: Dispatch) => {
        dispatch(actions.setMessageInput(text))
        dispatch(actions.setDraftedInput(text))
    }
}

export const addToMessageInputText = (text: string) => {
    return (dispatch: Dispatch) => {
        const state = store.getState().dialogs

        const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId ?? ""
        const currentInputText = dialogDraftsLocalStoreDriver.get(draftEntityId)
        const filteredText = trim(text, "\\\n")

        const pasteText = currentInputText ? `${currentInputText}\n\n ${filteredText}` : filteredText

        dispatch(setMessageInputText(pasteText))

        // without timeout it doesn't work in empty input
        setTimeout(() => scrollDownMessageInput(), 0)
    }
}
